<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-card>
          <v-card-title>
            <span class="text-h6">{{ isEditMode ? 'Edit Vehicle' : 'Create Vehicle' }}</span>
          </v-card-title>

          <v-card-text>
            <v-form ref="form">
              <v-text-field
                  v-model="vehicle.titleStatus"
                  label="Title Status"
                  required
              ></v-text-field>
              <v-text-field
                  v-model="vehicle.year"
                  label="Year"
                  type="number"
                  required
              ></v-text-field>
              <v-text-field
                  v-model="vehicle.amount_payed"
                  label="Amount Payed"
                  type="number"
                  required
              ></v-text-field>
              <v-text-field
                  v-model="vehicle.total_amount"
                  label="Price"
                  type="number"
                  required
              ></v-text-field>
              <v-text-field
                  v-model="vehicle.make"
                  label="Make"
                  required
              ></v-text-field>
              <v-text-field
                  v-model="vehicle.model"
                  label="Model"
                  required
              ></v-text-field>
              <v-text-field
                  v-model="vehicle.vin"
                  label="VIN"
              ></v-text-field>
              <v-autocomplete
                  v-model="vehicle.user"
                  :items="users"
                  item-title="name"
                  item-value="id"
                  label="Search and select user"
                  :loading="loadingUsers"
                  clearable
                  placeholder="Start typing to search users"
                  return-object
                  @update:search="fetchUsers"
              >
                <template #append-item>
                  <v-list-item
                      v-if="!users.length && !loadingUsers"
                      disabled
                      title="No users found"
                  ></v-list-item>
                </template>
              </v-autocomplete>
              <v-select
                  v-model="vehicle.port"
                  :items="loadingPorts"
                  item-text="name"
                  item-title="name"
                  item-value="id"
                  label="Port of Loading"
                  return-object
              ></v-select>
              <v-select
                  v-model="vehicle.destinationPort"
                  :items="destinationPorts"
                  item-text="name"
                  item-title="name"
                  item-value="id"
                  label="Port of Destination"
                  return-object
              ></v-select>
              <v-text-field
                  v-model="vehicle.towing_cost"
                  label="Towing Cost"
                  type="number"
              ></v-text-field>
              <v-text-field
                  v-model="vehicle.shipping_cost"
                  label="Shipping cost"
                  type="number"
              ></v-text-field>
              <v-text-field
                  v-model="vehicle.containerNumber"
                  label="Container Number"
              ></v-text-field>
              <v-text-field
                  v-model="vehicle.bookingNumber"
                  label="Booking Number"
              ></v-text-field>
              <v-text-field
                  v-model="vehicle.vehicleReady"
                  label="Vehicle Ready Date"
                  type="date"
              ></v-text-field>
              <v-text-field
                  v-model="vehicle.loadDate"
                  label="Load Date"
                  type="date"
              ></v-text-field>
              <v-text-field
                  v-model="vehicle.vehiclePicked"
                  label="Vehicle Pickup Date"
                  type="date"
              ></v-text-field>
              <v-text-field
                  v-model="vehicle.vehicleDelivered"
                  label="Vehicle Delivered Date"
                  type="date"
              ></v-text-field>
              <v-text-field
                  v-model="vehicle.estimatedDepartureDate"
                  label="Estimate Departure Date"
                  type="date"
              ></v-text-field>
              <v-text-field
                  v-model="vehicle.etd"
                  label="ETA"
                  type="date"
              ></v-text-field>
              <v-text-field
                  v-model="vehicle.originalEta"
                  label="actual ETA"
                  type="date"
              ></v-text-field>

              <!-- Current Status -->
              <v-select
                  v-model="vehicle.currentStatus"
                  :items="statuses"
                  item-title="status"
                  item-value="id"
                  label="Current Status"
                  required
                  return-object
              ></v-select>

            </v-form>


            <v-row>
              <v-col cols="8">
                <v-file-input v-model="thumbnailImage" label="Thumbnail Image" accept="image/*" @change="previewThumbnail" />
              </v-col>
              <v-col v-if="vehicle.thumbnailImage || thumbnailPreview" class="mb-3" cols="4">
                <v-img :src="thumbnailPreview || vehicle.thumbnailImage" style="width: 60px; height: 60px"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-file-input v-model="galleryImages" multiple label="Gallery Images" accept="image/*" />
              </v-col>
              <v-col v-if="vehicle.galleryImages.length" class="mb-6" cols="12">
                <v-row>
                  <v-col
                      v-for="n in vehicle.galleryImages"
                      :key="n"
                      class="d-flex child-flex position-relative"
                      cols="3"
                  >
                    <v-fab
                        icon="mdi-delete"
                        location="bottom left"
                        size="small"
                        absolute
                        style="left: 0"
                        app
                        @click="removeGalleryImage(n)"
                    ></v-fab>
                    <v-img
                        :lazy-src="n"
                        :src="n"
                        aspect-ratio="1"
                        class="bg-grey-lighten-2"
                        cover
                    >
                      <template v-slot:placeholder>
                        <v-row
                            align="center"
                            class="fill-height ma-0"
                            justify="center"
                        >
                          <v-progress-circular
                              color="grey-lighten-5"
                              indeterminate
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-file-input v-model="deliveryImages" multiple label="After Delivery Images" accept="image/*" />
              </v-col>
              <v-col v-if="vehicle.deliveryImages.length" class="mb-6" cols="12">
                <v-row>
                  <v-col
                      :key="n"
                      style="position: relative;"
                      v-for="n in vehicle.deliveryImages"
                      class="d-flex child-flex position-relative"
                      cols="3"
                  >
                    <v-fab
                        icon="mdi-delete"
                        location="bottom left"
                        size="small"
                        absolute
                        style="left: 0"
                        app
                        @click="removeDeliveryImage(n)"
                    ></v-fab>
                    <v-img
                        :lazy-src="n"
                        :src="n"
                        aspect-ratio="1"
                        class="bg-grey-lighten-2"
                        cover
                    >
                      <template v-slot:placeholder>
                        <v-row
                            align="center"
                            class="fill-height ma-0"
                            justify="center"
                        >
                          <v-progress-circular
                              color="grey-lighten-5"
                              indeterminate
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-btn :disabled="loadingVehicle || loadingDeliveryImageUpload || loadingGalleryUpload || loadingThumbnailUpload || loadingDeliveryImageUpload" :loading="loadingDeliveryImageUpload || loadingGalleryUpload || loadingThumbnailUpload || loadingDeliveryImageUpload" color="primary" @click="submitForm">
              {{ isEditMode ? 'Update' : 'Create' }}
            </v-btn>
            <v-btn text @click="$router.push('/vehicles')">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import vehicleService from '@/services/vehicleService';
import portService from "@/services/portService";
import statusService from "@/services/statusService";
import userService from "@/services/userService";

export default {
  data() {
    return {
      loadingVehicle: false, loadingGalleryUpload: false, loadingDeliveryImageUpload: false, loadingThumbnailUpload: false,
      users: [], // List of users to populate the autocomplete dropdown
      loadingUsers: false, // Indicates loading state while fetching users
      vehicle: {
        titleStatus: null,
        year: null,
        total_amount: null,
        amount_payed: null,
        make: null,
        model: null,
        vin: null,
        port: null,
        destinationPort: null,
        vehicleReady: null,
        shipping_cost: null,
        towing_cost: null,
        containerNumber: null,
        bookingNumber: null,
        loadDate: null,
        vehiclePicked: null,
        estimatedDepartureDate: null,
        etd: null,
        originalEta: null,
        currentStatus: null, // Track the current status
        thumbnailImage: null,
        galleryImages: [],
        deliveryImages: [],
      },
      ports: [],
      destinationPorts: [],
      loadingPorts: [],
      statuses: [], // Example statuses
      thumbnailImage: null,
      thumbnailPreview: null,
      galleryImages: [],
      deliveryImages: [],
    };
  },
  computed: {
    isEditMode() {
      return !!this.$route.params.id; // Check if we have an ID in the route
    },
  },
  created() {
    this.loadDestinationPorts();
    this.loadLoadingPorts();
    this.loadStatus();
    if (this.isEditMode) {
      this.loadVehicle();
    }
  },
  methods: {
    previewThumbnail() {
      if (this.thumbnailImage) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.thumbnailPreview = e.target.result;
        };
        reader.readAsDataURL(this.thumbnailImage);
      }
    },
    async fetchUsers(search) {
      this.loadingUsers = true;
      try {
        const response = await userService.searchUsers(search); // Assume this is a paginated search API
        this.users = response.data;
      } catch (error) {
        console.error('Error fetching users:', error);
        this.users = [];
      } finally {
        this.loadingUsers = false;
      }
    },
    async loadLoadingPorts() {
      try {
        const response = await portService.getLoadingPorts();
        this.loadingPorts = response.data;
      } catch (error) {
        console.error('Error loading ports:', error);
      }
    },
    async loadDestinationPorts() {
      try {
        const response = await portService.getDestinationPorts();
        this.destinationPorts = response.data;
      } catch (error) {
        console.error('Error loading ports:', error);
      }
    },
    async loadStatus() {
      try {
        const response = await statusService.getStatuses();
        console.log(response.data)
        this.statuses = response.data;
      } catch (error) {
        console.error('Error loading ports:', error);
      }
    },
    async loadVehicle() {
      const { id } = this.$route.params;
      try {
        const response = await vehicleService.getVehicle(id);
        this.vehicle = response.data;
      } catch (error) {
        console.error('Error loading vehicle:', error);
      }
    },
    async removeGalleryImage(imagePath) {
      try {
        await vehicleService.deleteGalleryImage(this.vehicle.id, imagePath);
        // Remove the image from the local state
        this.vehicle.galleryImages = this.vehicle.galleryImages.filter(img => img !== imagePath);
      } catch (error) {
        console.error('Error deleting gallery image:', error);
      }
    },

    async removeDeliveryImage(imagePath) {
      try {
        await vehicleService.deleteDeliveryImage(this.vehicle.id, imagePath);
        // Remove the image from the local state
        this.vehicle.deliveryImages = this.vehicle.deliveryImages.filter(img => img !== imagePath);
      } catch (error) {
        console.error('Error deleting delivery image:', error);
      }
    },
    async submitForm() {
      try {
        this.vehicle.towing_cost = Number(this.vehicle.towing_cost) || 0;
        this.vehicle.year = Number(this.vehicle.year) || 0;
        this.vehicle.amount_payed = Number(this.vehicle.amount_payed) || 0;
        this.vehicle.total_amount = Number(this.vehicle.total_amount) || 0;
        this.vehicle.shipping_cost = Number(this.vehicle.shipping_cost) || 0;
        try {
          this.vehicle.port = this.vehicle.port.id
        } catch (e) {
          //
        }
        try {
          this.vehicle.destinationPort = this.vehicle.destinationPort.id
        } catch (e) {
          //
        }

        try {
          this.vehicle.user = this.vehicle.user.id
        } catch (e) {
          //
        }
        try {
          this.vehicle.currentStatus = this.vehicle.currentStatus.id
        } catch (e) {
          //
        }
        if (this.isEditMode) {
          this.loadingVehicle = true;
          await vehicleService.updateVehicle(this.$route.params.id, this.vehicle);
          this.loadingVehicle = false;

          if (this.thumbnailImage) {
            this.loadingThumbnailUpload = true;
            await vehicleService.uploadThumbnail(this.$route.params.id, this.thumbnailImage);
            this.loadingThumbnailUpload = false;
          }
          if (this.galleryImages.length) {
            this.loadingGalleryUpload = true;
            await vehicleService.uploadGallery(this.$route.params.id, this.galleryImages);
            this.loadingGalleryUpload = false;
          }
          if (this.deliveryImages.length) {
            this.loadingDeliveryImageUpload = true;
            await vehicleService.uploadAfterDelivery(this.$route.params.id, this.deliveryImages);
            this.loadingDeliveryImageUpload = false;
          }
        } else {
          this.loadingVehicle = true;
          const response = await vehicleService.createVehicle(this.vehicle);
          const newVehicleId = response.data.id;
          this.loadingVehicle = false;

          if (this.thumbnailImage) {
            this.loadingThumbnailUpload = true;
            await vehicleService.uploadThumbnail(newVehicleId, this.thumbnailImage);
            this.loadingThumbnailUpload = false;
          }
          if (this.galleryImages.length) {
            this.loadingGalleryUpload = true;
            await vehicleService.uploadGallery(newVehicleId, this.galleryImages);
            this.loadingGalleryUpload = false;
          }
          if (this.deliveryImages.length) {
            this.loadingDeliveryImageUpload = true;
            await vehicleService.uploadAfterDelivery(newVehicleId, this.deliveryImages);
            this.loadingDeliveryImageUpload = false;
          }
        }
        this.$router.push('/vehicles');
      } catch (error) {
        console.error('Error saving vehicle:', error);
      }
    },
  },
};
</script>
